import Axios from "axios";
import { v4 as uuidV4 } from "uuid";

import { fetcher } from "utils/axios";

import { getFileURL, removeExtensionFromFileName, sanitizeFileName } from "utils/utilities";
import { notifyMessage } from "utils/notification";

export const uploadFileToS3 = async (file, dir = "image") => {
  try {
    const fileDir = removeExtensionFromFileName(file.name);
    const params = { key: `${dir}/${fileDir}/${uuidV4()}/${file.name}`, mimetype: file.type };
    const response = await fetcher(`/admin/upload/s3-signed-url`, { params });

    const signedURL = response.data.data?.signedURL;

    if (!signedURL) {
      throw new Error(response.data.message || "Unable to retrieve signed URL.");
    }

    await Axios.put(signedURL, file, { headers: { "Content-Type": file.type } });

    notifyMessage(`File successfully uploaded`);

    return getFileURL(signedURL);
  } catch (error) {
    throw error;
  }
};

export const uploadZipFileToS3 = async (file, dir = "image") => {
  try {
    const maxSizeInBytes = 150 * 1024 * 1024; // 150MB
    if (file.size > maxSizeInBytes) {
      throw new Error("File size exceeds the 150MB limit");
    }

    const zipCheckRegex = /^[a-zA-Z0-9_-]+\.zip$/; // Allows only .zip files
    if (!zipCheckRegex.test(file.name)) {
      throw new Error("Invalid input! Only .zip files are allowed. Ensure the name contains only letters, numbers, underscores (_), hyphens (-), and ends with '.zip'.");
    }

    const regex = /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9]+)?$/;
    if (!regex.test(file.name)) {
      throw new Error(
        "Invalid input! Only letters, numbers, underscores (_), hyphens (-), and an optional extension (e.g., .txt) are allowed. No spaces or other special characters.",
      );
    }

    const fileName = sanitizeFileName(file.name);

    const params = { key: `${dir}/${fileName}`, mimetype: file.type };
    const response = await fetcher(`/admin/upload/s3-signed-url`, { params });

    const signedURL = response.data.data?.signedURL;

    if (!signedURL) {
      throw new Error(response.data.message || "Unable to retrieve signed URL.");
    }

    await Axios.put(signedURL, file, { headers: { "Content-Type": file.type } });

    notifyMessage(`File successfully uploaded`);

    return getFileURL(signedURL);
  } catch (error) {
    throw error;
  }
};

export const getS3MultipartSignedURLs = async ({ queryKey }) => {
  const [, params] = queryKey;
  const response = await fetcher(`/admin/upload/s3-signed-url/multipart`, { params });

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const completeS3MultipartUpload = async (payload) => {
  const response = await fetcher.put(`/admin/upload/s3-signed-url/multipart/complete`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const abortS3MultipartUpload = async (payload) => {
  const response = await fetcher.put(`/admin/upload/s3-signed-url/multipart/abort`, payload);

  if (response.status === 200) {
    return response.data.data;
  }

  throw new Error(response.data.message);
};

export const checkFileAvailability = async (url) => {
  try {
    await Axios.head(url);
    return true;
  } catch (error) {
    throw error;
  }
};
